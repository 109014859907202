import React, { useContext } from 'react';
import './expertise.scss';
import { Link } from 'gatsby';
import { ArrowForward } from '@material-ui/icons';
import cx from 'classnames';
import Button from '../Button';
import Badge from '../Badge';
import { LangContext } from '../Layout';

export default function Expertise({ expertises }) {
  const { language } = useContext(LangContext);

  return (
    <div className={'expertise'}>
      <div className={'expertise__left'}>
        <div className="expertise__left__ticket">
          <Badge text={expertises['headline_' + language]} />
        </div>
        <p className={'expertise__left__title'}>
          {expertises['title_' + language]}
        </p>
        <div className={'expertise__left__description'}>
          {expertises['description_' + language]}
        </div>

        <div className="expertise__left__link">
          <Button
            text={expertises['button_' + language]}
            to={'/expertise/web'}
          />
        </div>
      </div>
      <div className={'expertise__right'}>
        <div className={'expertise__right__exp'}>
          {expertises.content.map((expertise, i) => {
            let isblue = i % 2;
            return (
              <div className={'expertise__right__exp__container'} key={i}>
                <Link
                  className={'expertise__right__exp__container__card'}
                  to={expertise.url}
                >
                  <div
                    className={cx(
                      'expertise__right__exp__container__card__image',
                      {
                        expertise__right__exp__container__card__image_blue: isblue,
                      },
                    )}
                  >
                    <img
                      src={expertise.image.publicURL}
                      alt={expertise['title_' + language]}
                    />
                  </div>
                  <div
                    className={
                      'expertise__right__exp__container__card__description'
                    }
                  >
                    <h1
                      className={
                        'expertise__right__exp__container__card__description__title'
                      }
                    >
                      {expertise['title_' + language]}{' '}
                      <ArrowForward
                        fontSize="small"
                        className="materiel-icon"
                      />
                    </h1>
                    <div
                      className={
                        'expertise__right__exp__container__card__description__p'
                      }
                    >
                      {expertise['description_' + language]}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
