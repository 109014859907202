import React from 'react';
import './style.scss';
import { ArrowForward } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

function Button({ text, to }) {
  return (
    <Link to={to || '/'}>
      <button>
        {text}
        <ArrowForward fontSize="small" className="materiel-icon" />
      </button>
    </Link>
  );
}

Button.propTypes = {
  text: PropTypes.string,
};
export default Button;
